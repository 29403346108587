.navbar .fade-in {
  animation-name: fade-in;
  animation-duration: 0.5s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  -webkit-animation-name: fade-in;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-delay: 0s;
  -webkit-animation-fill-mode: forwards;
  opacity: 1;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.navbar .fade-out {
  animation-name: fade-out;
  animation-duration: 0.5s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  -webkit-animation-name: fade-out;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-delay: 0s;
  -webkit-animation-fill-mode: forwards;
  opacity: 1;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.navbar .underline {
  text-decoration: underline;
}
