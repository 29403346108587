.content-header .icon {
  border: 2px solid var(--secondary);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  text-align: center;
  vertical-align: bottom;
  padding: 8px;
  transition: transform 0.5s ease;
}

.content-header .icon:hover {
  transform: scale(1.1);
}
