.title-card-container .link {
  transition: transform 1s ease;
}

.title-card-container .link:hover {
  transform: scale(1.02);
}

.title-card-container .enlarge-fullscreen {
  animation-name: enlarge-fullscreen;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  -webkit-animation-name: enlarge-fullscreen;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
}

.title-card-container .enlarge-fullscreen-reverse {
  animation-name: enlarge-fullscreen-reverse;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  -webkit-animation-name: enlarge-fullscreen-reverse;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-fill-mode: forwards;
}

@keyframes enlarge-fullscreen {
  0% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1.55);
  }
}

@keyframes enlarge-fullscreen-reverse {
  0% {
    height: 105%;
    width: 105%;
  }
  100% {
    height: 80%;
    width: calc(50% + 250px);
  }
}

.title-card-container .background-darken {
  filter: brightness(80%);
}

.title-card-container .fade-in-out {
  animation-name: fade-in-out;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  -webkit-animation-name: fade-in-out;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
}

@keyframes fade-in-out {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  33% {
    transform: translateY(0px);
    opacity: 0;
  }
  66% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.title-card-container .remove-fade-in {
  animation-name: remove-fade-in;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  -webkit-animation-name: remove-fade-in;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
}

@keyframes remove-fade-in {
  0% {
    transform: translateY(0px);
    opacity: 0;
  }
  50% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.title-card-container h1 {
  font-size: calc(16px + 2.5vw);
}

.title-card-container h2 {
  font-size: 16px;
}
